import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Bounce, toast, ToastContainer } from "react-toastify";
import Button from "../common/Button";
import { submitXenithKyc } from "../../redux/actions/xenithActions";

const userDetails = {
  passport: null,
  selfie: null,
  address: "",
  city: "",
  pin: "",
  dob: "",
  country: "",
};

const XenithKycForm = () => {
  const [user, setUser] = useState(userDetails);
  const [passportPreview, setPassportPreview] = useState(null);
  const [selfiePreview, setSelfiePreview] = useState(null);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const checkoutConfig = useSelector((state) => state?.reducer?.checkoutConfig);
  const [loadingverify, setLoadingVerify] = useState(false);
  const keyUsed = useSelector(
    (state) => state?.reducer?.checkoutConfig?.keyUsed
  );

  const handleImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (500KB = 500 * 1024 bytes)
      if (file.size > 500 * 1024) {
        showErrorMessage(`${type === 'passport' ? 'Passport' : 'Selfie'} image size must be under 500KB`);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        setUser((prev) => ({
          ...prev,
          [type]: base64Data,
        }));
        if (type === "passport") {
          setPassportPreview(reader.result);
        } else if (type === "selfie") {
          setSelfiePreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (field, value) => {
    setUser((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    setLoadingVerify(true);

    const formatDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const response = dispatch(
      submitXenithKyc(
        {
          passport: user.passport,
          selfie: user.selfie,
          address: user.address,
          city: user.city,
          pin: user.pin,
          dob: formatDate(user.dob),
          country: user.country,
        },
        keyUsed,
        checkoutConfig?._id
      )
    );

    setLoadingVerify(false);
    navigate("/xenith/kyc/awaiting");

    // if (response.code === 1) {
    //   navigate("/xenith");
    // } else {
    //   showErrorMessage(response.data);
    // }
  };

  const validateCountryCode = (country) => {
    // Check if it's exactly 2 letters and contains only alphabets
    return /^[A-Za-z]{2}$/.test(country);
  };

  const validateFields = () => {
    const errors = {};
    if (!user.passport) {
      errors.passport = "Please upload your passport";
    }
    if (!user.selfie) {
      errors.selfie = "Please upload your selfie";
    }
    if (!user.address.trim()) {
      errors.address = "Please enter your address";
    }
    if (!user.city.trim()) {
      errors.city = "Please enter your city";
    }
    if (!user.country.trim()) {
      errors.country = "Please enter your country";
    } else if (!validateCountryCode(user.country.trim())) {
      errors.country = "Please enter a valid 2-letter country code (e.g., US, UK, IN)";
    }
    if (!user.pin.trim()) {
      errors.pin = "Please enter your postal code";
    }
    if (!user.dob) {
      errors.dob = "Please select your date of birth";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const showErrorMessage = (errMessage) =>
    toast.error(errMessage, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "colored",
      transition: Bounce,
    });

  return (
    <div className="w-full flex flex-col items-center justify-start pb-[100px]">
      <div className="w-full bnksy-input-group mb-[20px]">
        <label className="block mb-2 text-sm font-medium">Address</label>
        <input
          type="text"
          className="bnksy-form-input"
          placeholder="Address"
          value={user.address}
          onChange={(e) => handleInputChange("address", e.target.value)}
          required
        />
        {validationErrors.address && (
          <p className="text-red-600 text-sm">{validationErrors.address}</p>
        )}
      </div>

      <div className="flex justify-between w-full">
        <div className="w-full bnksy-input-group mb-[20px] mr-5">
          <label className="block mb-2 text-sm font-medium">City</label>
          <input
            type="text"
            className="bnksy-form-input"
            placeholder="City"
            value={user.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
            required
          />
          {validationErrors.city && (
            <p className="text-red-600 text-sm">{validationErrors.city}</p>
          )}
        </div>
        <div className="w-full bnksy-input-group mb-[20px]">
          <label className="block mb-2 text-sm font-medium">Country</label>
          <input
            type="text"
            className="bnksy-form-input"
            placeholder="Country code (e.g., US, UK, IN)"
            value={user.country}
            onChange={(e) => handleInputChange("country", e.target.value.toUpperCase())}
            maxLength={2}
            required
          />
          {validationErrors.country && (
            <p className="text-red-600 text-sm">{validationErrors.country}</p>
          )}
        </div>
      </div>

      <div className="flex justify-between w-full">
        <div className="w-full bnksy-input-group mb-[20px] mr-5">
          <label className="block mb-2 text-sm font-medium">
            Date of Birth
          </label>
          <div className="relative">
            <DatePicker
              selected={user.dob ? new Date(user.dob) : null}
              onChange={(date) => handleInputChange("dob", date)}
              dateFormat="yyyy-MM-dd"
              className="bnksy-form-input"
              placeholderText="Date Of Birth"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              maxDate={new Date()}
              required
            />
            <div className="absolute calendar-icon-box right-[16px] top-1/2 -translate-y-1/2 z-0">
              <img
                src="/calendar-icon.svg"
                alt="logo"
                className="w-full logo-transparent-white"
              />
            </div>
          </div>
          {validationErrors.dob && (
            <p className="text-red-600 text-sm">{validationErrors.dob}</p>
          )}
        </div>

        <div className="w-full bnksy-input-group mb-[20px]">
          <label className="block mb-2 text-sm font-medium">Postal Code</label>
          <input
            type="text"
            className="bnksy-form-input"
            placeholder="Postal code"
            value={user.pin}
            onChange={(e) => handleInputChange("pin", e.target.value)}
            required
          />
          {validationErrors.pin && (
            <p className="text-red-600 text-sm">{validationErrors.pin}</p>
          )}
        </div>
      </div>

      <div className="w-full">
        <div className="flex items-center gender-title relative mb-[20px]">
          <span className="common-lbl text-center w-full">
            Select Documents
          </span>
        </div>
        <div className="flex gap-6">
          <div className="w-1/2">
            <div className="text-center">
              <div className="flex items-center common-lbl mb-[10px]">
                <span>Passport Photo</span>
              </div>
              <div
                className={`relative overflow-hidden w-full flex flex-col items-center ${
                  passportPreview ? "justify-center" : "justify-center"
                } upload-box mx-auto`}
              >
                <div
                  className={`flex items-center ${
                    passportPreview ? "justify-between" : "justify-center"
                  } w-full h-full`}
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    id="passport-upload"
                    onChange={(e) => handleImageUpload(e, "passport")}
                    required
                  />
                  {!passportPreview && (
                    <label
                      htmlFor="passport-upload"
                      className="flex items-center justify-center cursor-pointer w-full h-full"
                    >
                      <img
                        src="/upload.svg"
                        alt="icon"
                        className="logo-transparent-white"
                      />
                      <div className="flex flex-col text-left ml-3">
                        <span className="common-lbl">Upload file</span>
                        <span className="common-lbl font-small upload-small-lbl">
                          Format: JPG, PNG (Max: 500KB)
                        </span>
                      </div>
                    </label>
                  )}
                  {passportPreview && (
                    <>
                      <label
                        htmlFor="passport-upload"
                        className="Upload-EditIcon bg-primary text-white cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-camera-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                          <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                        </svg>
                      </label>
                      <img
                        src={passportPreview}
                        alt="Passport Preview"
                        className="max-w-full w-full h-full object-contain"
                      />
                    </>
                  )}
                </div>
              </div>
              {validationErrors.passport && (
                <p className="text-left text-red-600 font-small">
                  {validationErrors.passport}
                </p>
              )}
            </div>
          </div>

          <div className="w-1/2">
            <div className="text-center">
              <div className="flex items-center common-lbl mb-[10px]">
                <span>Selfie</span>
              </div>
              <div
                className={`relative overflow-hidden w-full flex flex-col items-center ${
                  selfiePreview ? "justify-center" : "justify-center"
                } upload-box mx-auto`}
              >
                <div
                  className={`flex items-center ${
                    selfiePreview ? "justify-between" : "justify-center"
                  } w-full h-full`}
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    id="selfie-upload"
                    onChange={(e) => handleImageUpload(e, "selfie")}
                    required
                  />
                  {!selfiePreview && (
                    <label
                      htmlFor="selfie-upload"
                      className="flex items-center justify-center cursor-pointer w-full h-full"
                    >
                      <img
                        src="/upload.svg"
                        alt="icon"
                        className="logo-transparent-white"
                      />
                      <div className="flex flex-col text-left ml-3">
                        <span className="common-lbl">Upload file</span>
                        <span className="common-lbl font-small upload-small-lbl">
                          Format: JPG, PNG (Max: 500KB)
                        </span>
                      </div>
                    </label>
                  )}
                  {selfiePreview && (
                    <>
                      <label
                        htmlFor="selfie-upload"
                        className="Upload-EditIcon bg-primary text-white cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-camera-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                          <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                        </svg>
                      </label>
                      <img
                        src={selfiePreview}
                        alt="Selfie Preview"
                        className="max-w-full w-full h-full object-contain"
                      />
                    </>
                  )}
                </div>
              </div>
              {validationErrors.selfie && (
                <p className="text-left text-red-600 font-small">
                  {validationErrors.selfie}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
        <Button
          onClick={handleSubmit}
          loading={loadingverify}
          disabled={
            !user.passport ||
            !user.selfie ||
            !user.address ||
            !user.city ||
            !user.country ||
            !user.pin ||
            !user.dob
          }
        >
          Submit
        </Button>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default XenithKycForm;
