import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";
import Awaiting from "../../../component/common/Awaiting";
import { fetchXenithKycStatus } from "../../../redux/actions/xenithActions";
import { useDispatch, useSelector } from "react-redux";

const XenithKycAwaiting = () => {
  const navigate = useNavigate();

  const apiKey = useSelector((state) => state?.reducer?.checkoutConfig?.keyUsed);
  const paymentId = useSelector(
    (state) => state?.reducer?.checkoutConfig?._id
  );
  const dispatch = useDispatch();
  const [status, setStatus] = useState("awaiting");

  const checkStatus = async () => {
    const response = await dispatch(fetchXenithKycStatus(apiKey, paymentId));
    if (response.data.status == "FULL_USER") {
      navigate("/xenith");
    } else {
      setStatus("awaiting");
    }
  };

  useEffect(() => {
    checkStatus();
    let intervalId;
    if (status === "awaiting") {
      intervalId = setInterval(checkStatus, 2000); // Check every 5 seconds
    }
    return () => clearInterval(intervalId);
  }, [status]);
 

 

  return (
    <RootLayout>
      <Awaiting
        buttonText={"Go back"}
        title="Please wait..."
        description="Your KYC is being processed. This typically takes about 10-40 seconds, but may take up to 10 minutes if manual review is needed. Our verification success rate is 96-97%."
        imageSource="/Please-Wait.png"
      />
    </RootLayout>
  );
};

export default XenithKycAwaiting;
