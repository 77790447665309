import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Checkout from "../screen/checkout";
import Index from "../screen";
import ConfigError from "../screen/error/config";
import StripeSuccess from "../screen/success/stripe";
import WyrapaySuccess from "../screen/success/wyarpay";
import TripleASuccess from "../screen/success/trplea";
import Timeout from "../screen/error/timeout";
import StripeFailure from "../screen/error/stripe";
import TripleAawaiting from "../screen/awaiting/triplea";
import Signin from "../screen/auth/signin";
import VerifyOtp from "../screen/auth/verifyOtp";
import RegisterKyc from "../screen/kyc/registerKyc";
import KycStatus from "../screen/kyc/status";
import Crypto from "../screen/crypto";
import MudrexKycSuccess from "../screen/success/mudrex/mudrexKyc";
import PaymentMethods from "../screen/success/mudrex/paymentMethods";
import MudrexPaymentStatus from "../screen/awaiting/mudrex";
import SumupSuccess from "../screen/success/sumup";
import Pinwheel from "../screen/pinwheel";
import PinwheelPaymentStatus from "../screen/awaiting/pinwheel";
import Centaurus from "../screen/centaurus";
import CentaurusPaymentStatus from "../screen/awaiting/centaurus";
import Sombrero from "../screen/sombrero";
import WhirlpoolPaymentStatus from "../screen/success/whirlpool";
import Blackeye from "../screen/blackEye";
import BlackeyePaymentStatus from "../screen/awaiting/blackEye";
import PaymentProcessed from "../screen/info/paymentProcessed";
import PegasusSuccess from "../screen/success/pegasus";
import PerseusSuccess from "../screen/success/perseus";
import XenithKyc from "../screen/xenith/kyc";
import Xenith from "../screen/xenith";
import Xenon from "../screen/awaiting/xenon"
import XenithKycAwaiting from "../screen/awaiting/xenith/kyc";
import XenithPaymentStatus from "../screen/awaiting/xenith";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/error/config",
    element: <ConfigError />,
  },
  {
    path: "error/stripe",
    element: <StripeFailure />,
  },
  {
    path: "/error/timeout",
    element: <Timeout />,
  },
  {
    path: "/info/payment-processed",
    element: <PaymentProcessed />,
  },
  {
    path: "/success/stripe",
    element: <StripeSuccess />,
  },
  {
    path: "/success/wyrapay",
    element: <WyrapaySuccess />,
  },
  {
    path: "/success/pegasus",
    element: <PegasusSuccess />,
  },
  {
    path: "/success/triple-a/:apikey",
    element: <TripleASuccess />,
  },
  {
    path: "/auth/signin",
    element: <Signin />,
  },
  {
    path: "/auth/otp",
    element: <VerifyOtp />,
  },
  {
    path: "/awaiting/triplea",
    element: <TripleAawaiting />,
  },
  {
    path: "/auth/kyc",
    element: <RegisterKyc />,
  },
  {
    path: "/auth/kyc/status",
    element: <KycStatus />,
  },
  {
    path: "/checkout/crypto",
    element: <Crypto />,
  },
  {
    path: "/checkout/pinwheel",
    element: <Pinwheel />,
  },
  {
    path: "/checkout/centaurus",
    element: <Centaurus />,
  },
  {
    path: "/checkout/sombrero",
    element: <Sombrero />,
  },
  {
    path: "/checkout/blackeye",
    element: <Blackeye />,
  },
  {
    path: "/mudrex/payment/methods",
    element: <PaymentMethods />,
  },
  {
    path: "/success/mudrex/kyc",
    element: <MudrexKycSuccess />,
  },
  {
    path: "/awaiting/mudrex",
    element: <MudrexPaymentStatus />
  },
  {
    path: "/awaiting/pinwheel",
    element: <PinwheelPaymentStatus />
  },
  {
    path: "/awaiting/centaurus",
    element: <CentaurusPaymentStatus />,
  },
  {
    path: "/awaiting/blackeye",
    element: <BlackeyePaymentStatus />,
  },
  {
    path: "/success/whirlpool",
    element: <WhirlpoolPaymentStatus />,
  },
  {
    path: "/success/sumup/:apikey",
    element: <SumupSuccess />,
  },
  {
    path: "/success/perseus/:apikey",
    element: <PerseusSuccess />,
  },
  {
    path: "/error/perseus/:apikey",
    element: <PerseusSuccess />,
  },
  {
    path: "/xenith/kyc",
    element: <XenithKyc />
  },
  {
    path: "/xenith",
    element: <Xenith />
  },
  {
    path: "/xenon",
    element: <Xenon />
  },
  {
    path:"/xenith/kyc/awaiting",
    element: <XenithKycAwaiting />
  },
  {
    path:"/xenith/awaiting",
    element: <XenithPaymentStatus />
  }
]);

export default router;
